import Layout from '@/layout/default'
const digitalRouter = {
    path: '/digital',
    name: 'digital',
    component: Layout,
    children: [
        {
            path: 'list', // 我的藏品
            name: 'digital-list',
            component: () => import('@/views/digital/list.vue')
        }
    ]
}

export default digitalRouter
