import { getToken } from '@/utils/user'
import { apiClient } from 'md-base-tools/apiClient'
import MDOSS from "md-base-tools/ossUpload"

const header = {
  client: 4,
  user_id: getToken() ? getToken().split('#')[0] : '',
  userid: getToken() ? getToken().split('#')[0] : '',
  token: getToken() ? getToken().split('#')[1] : ''
}
const callback = function(err) {
  console.log('err', err)
}
const needLogin = false
const env = process.env.VUE_APP_ENV // 环境
console.log('env', process.env)
const apis = apiClient({
  env,
  header,
  needLogin,
  callback
})

export const post = (url, option, new_header) => {
  return apis({
    method: 'post',
    url: url,
    props: {
      json_type: 1,
      ...option
    },
    header: {
      ...header,
      ...new_header
    }
  })
}
export const get = (url, option, new_header) => {
  return apis({
    method: 'get',
    url: url,
    props: {
      json_type: 1,
      ...option
    },
    header: {
      ...header,
      ...new_header
    }
  })
}
export const OSSUpload = MDOSS( apis, getToken() ? getToken().split('#')[0] : '')
export default apis
