import { get } from '@/utils/apiBase.js';

// 判读是否是CP
const GetIfCp = (option) => {
  return get('/apis/comm/user/is_cp', option);
};
// 获取cp用户信息
const GetCpUserInfo = (option) => {
    return get('/apis/comm/user/cp/user_info', option);
};


export default {
    GetIfCp,
    GetCpUserInfo
};
