let prefix = 'https://'
let suffix = '.modian'

if (process.env.NODE_BASE === 'local') {
  prefix = 'http://'
} else if (process.env.NODE_ENV !== '/' && process.env.NODE_ENV !== 'production') {
  prefix += process.env.VUE_APP_ENV
  suffix += process.env.VUE_APP_ENV !=='pre' ? 'inc' : ''
}

suffix += '.com'

const domain = {
  zhongchou: prefix + 'zhongchou' + suffix,
  login: prefix + 'login' + suffix,
  wap: prefix + 'm' + suffix,
  apim: prefix + 'apim' + suffix,
  me: prefix + 'me' + suffix,
  wds: prefix + 'wds' + suffix,
  zixun: prefix + 'zixun' + suffix,
  www: prefix + 'www' + suffix,
  special: prefix + 'special' + suffix,
  subscribe: prefix + 't' + suffix,
  kuji: prefix + 'kuji' + suffix,
  account: prefix + 'account' + suffix,
  member: prefix + 'member' + suffix,
  center: prefix + 'center' + suffix,
  suffix: suffix
}

export default domain
