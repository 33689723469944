import { get } from '@/utils/apiBase.js';

// 个人中心获取lockerr code
const getLockerrCode = (option) => {
  return get('/apis/user_center/get_lockerr_code', option);
};

// 获取手机号区号
const getCountryCode = (option) => {
  return get('/account/country_code', option);
};

const feedList = option => {
  return get('/apis/recommend/feed_list_v2', option)
}
// 获取跳转在线客服链接
const getSobotUrl = option => {
  return get('/apis/user_auth/get_sobot_url', option)
}



export default {
  getLockerrCode,
  getCountryCode,
  feedList,
  getSobotUrl
};
