import Cookies from 'js-cookie'
import { goToLogin as goLogin } from 'md-base-tools/apiClient/redirect'
const TokenKey = 'MDUSERTOKEN'
const IconKey = 'MDUSERINFO'

export function getToken (key = TokenKey) {
  return Cookies.get(key) || localStorage.getItem(key)
}
export function getIcon (key = IconKey) {
  return Cookies.get(key) || localStorage.getItem(key)
}
// 跳转登录方法
export function goToLogin () {
  goLogin('m', process.env.VUE_APP_ENV)
}
