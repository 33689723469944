<template>
    <!-- <div class="icon-comment" @click="goToRong"></div>
    <div class="num" v-if="unReadCount > 0">{{unReadCount}}</div>
    <div class="tips" v-if="unReadCount > 0">{{unReadCount}}条未读私信</div> -->
    <el-badge :value="unReadFromApi" :hidden="(unReadFromApi < 1)">
        <div class="icon-comment" @click="goToRong"></div>
    </el-badge>
    <div class="tips" v-if="unReadFromApi > 0">{{unReadFromApi}}条未读私信</div>
</template>

<script>
import domain from '@/service/domain'
import * as RongIMLib from '@rongcloud/imlib-next'
import { getUnreadCountApi } from "@api";
import { mapState, mapActions } from 'vuex'
export default {
    props: ["is_cp"],
    computed: {
        ...mapState({
            userId: state => state.user.userId,
            rongYunToken: state => state.im.rongYunToken,
            unReadCount: state => state.im.unReadCount,
            connected: state => state.im.connected,
        }),
        appkey(){
            return /test/.test(location.host) ? this.$store.state.im.appkeyTest : this.$store.state.im.appkey;
        },
    },
    watch: {
        unReadCount: {
            handler (val) {
                console.log('监听前端自己计算的未读数：'+val)
                if(val > this.unReadFromApi){
                    console.log('大于API返回的，重新赋值')
                    this.unReadFromApi = val;
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            unReadMessageLength: 0,
            unReadFromApi: 0,
            startTime: 0, // 获取远端会话列表参数：起始时间
            lastMessageReadCount: 0, // 消息列表最后一项的未读数，判断是否需要翻页继续请求
        }
    },
    async created() {
        try {
            const { data } = await getUnreadCountApi({ json_type: "json" });
            this.unReadFromApi = data.count;
        } catch (e) {
            console.log(e);
        }
        if (this.userId && this.unReadCount == 0) {
            await this.getImToken(this.userId)
            if (this.rongYunToken){
                if(this.connected){
                    this.getConverList();
                }else{
                    RongIMLib.init({ appkey: this.appkey });
                    const Events = RongIMLib.Events

                    /**
                     * 手动调用 disconnect 方法或者用户被踢下线 会触发这个事件
                     */
                    RongIMLib.addEventListener(Events.DISCONNECT, (code) => {
                        console.log('连接中断，需要业务层进行重连处理 ->', code)
                        this.$store.dispatch("currentConnectedAction", false);
                    })

                    /**
                     * 链接出问题时，内部进行重新链接，会触发这个事件
                     */
                    RongIMLib.addEventListener(Events.SUSPEND, (code) => {
                        // console.log('链接中断，SDK 会尝试重连，业务层无需关心')
                        console.log(`code -> ${code}`)
                    })

                    RongIMLib.addEventListener(Events.CONNECTING, function() {
                        // console.log('正在链接服务器')
                    })

                    RongIMLib.addEventListener(Events.CONNECTED, () => {
                        // console.log('已经链接到服务器')
                        this.$store.dispatch("currentConnectedAction", true);
                    })
                    // 接收消息监听器
                    RongIMLib.addEventListener(Events.MESSAGES, (evt) => {
                        let message = evt.messages[0];
                        console.log('收到新消息', message);
                        if(message.messageType != 'RC:SRSMsg'){
                            if(message.senderUserId.split('md_')[1] == this.userId){
                                console.log('自己发出的消息不计入未读数')
                                return false;
                            }
                            this.unReadMessageLength++;
                            this.$store.dispatch("currentUnReadCountAction", this.unReadMessageLength > 99 ? 99 : this.unReadMessageLength);
                        }
                    })
                    RongIMLib.connect(this.rongYunToken).then(res => {
                        if (res.code === RongIMLib.ErrorCode.SUCCESS) {
                            console.log('链接成功, 链接用户 id 为: ', res.data.userId);
                            this.getConverList()
                        } else {
                            console.warn('链接失败, code:', res.code)
                        }
                    })
                }
                
            }
        }
    },
    methods: {
        ...mapActions({
            getImToken: "getImToken",
        }),
        // 获取会话列表并单独请求会话未读数和历史消息的RC:SRSMsg二次判断未读数逻辑
        getConverList(){
            const startTime = 0;
            const count = 40;
            const order = 0;

            RongIMLib.getConversationList({
                count: count,
                startTime: startTime,
                order: order
            }).then(res => {
                if (res.code === 0) {
                    console.log(res.code, res.data)
                    this.dealListCount(res.data)
                } else {
                    console.log(res.code, res.msg)
                }
            })
        },
        // 处理会话列表
        async dealListCount(list){
            list.length > 0 ? this.startTime = (list[list.length - 1].latestMessage ? list[list.length - 1].latestMessage.sentTime : 0) : this.startTime = 0;
            for(let i = 0,j = list.length;i<j;i++){
                let HistoryObj = {};
                HistoryObj = await this.getHistoryAsync({
                    targetId:list[i].targetId,
                    timestrap:0,
                }).catch(err => {
                    console.log(err)
                });
                console.log(HistoryObj)
                if(HistoryObj && HistoryObj.list && HistoryObj.list.length > 0){
                    let lastMessage = HistoryObj.list[HistoryObj.list.length - 1];
                    let unreadMessageCount = 0;
                    if(lastMessage && lastMessage.messageType && lastMessage.messageType != 'RC:SRSMsg'){
                        unreadMessageCount = await this.getUnreadCount(list[i].targetId);
                        let historyResult = HistoryObj.list;
                        for(let a = historyResult.length - 1,b = historyResult.length - unreadMessageCount;a >= b;a--){
                            if(historyResult[a] && historyResult[a].messageType && historyResult[a].messageType == 'RC:SRSMsg'){
                                unreadMessageCount = historyResult.length - a;
                                console.log('命中RC:SRSMsg消息，更新未读为：'+unreadMessageCount);
                                return false;
                            }
                        }
                    }
                    if(unreadMessageCount > 0){
                        this.unReadMessageLength += unreadMessageCount;
                        if(i == list.length - 1){
                            this.lastMessageReadCount = unreadMessageCount;
                        }
                    }
                }else{
                    console.log(list[i].targetId+'历史消息为空，不计算未读数');
                }
            }
            this.$store.dispatch("currentUnReadCountAction", this.unReadMessageLength > 99 ? 99 : this.unReadMessageLength);
            console.log('最后一项未读数：'+this.lastMessageReadCount);
            if(this.unReadMessageLength < 99 && this.lastMessageReadCount > 0){
                this.unReadAjax();
            }
        },
        unReadAjax(){
            setTimeout(() =>{
                console.log('------当前未读会话条数'+this.unReadMessageLength+'，3S后继续请求~');
                this.getExtraConverList(this.startTime);
            }, 3000)
        },
        // 获取会话的历史消息
        async getHistoryAsync(params){
            return new Promise(function(resolve,reject){
                console.log(params);
                const conversation = {
                    conversationType: RongIMLib.ConversationType.PRIVATE,
                    targetId: params.targetId
                }
                const option = {
                    timestamp:params.timestrap,
                    count: 40,
                    order: 0
                };
                console.log(conversation)
                RongIMLib.getHistoryMessages(conversation, option).then(res => {
                    if (res.code === 0) {
                        resolve({
                            list:res.data.list,      // 获取到的消息列表
                            hasMore:res.data.hasMore // 是否还有历史消息可获取
                        });
                    } else {
                        console.log(res.code, res.msg)
                        console.log('获取历史消息失败', res.code, res.msg);
                        reject(res.msg)
                    }
                }).catch(error => {
                    console.log('获取历史消息失败', error.code, error.msg);
                    reject(error.msg)
                });
            })
        },
        //获取指定会话未读数
        async getUnreadCount(id){
            return new Promise(function(resolve){
                const conversationType = RongIMLib.ConversationType.PRIVATE;
                const targetId = id;
                RongIMLib.getUnreadCount({ conversationType, targetId }).then(res => {
                    if (res.code === 0) {
                        console.log('获取'+id+'指定会话未读数成功==>'+res.data);
                        console.log(res.code, res.data)
                        resolve(res.data);
                    } else {
                        console.log(res.code, res.msg)
                    }
                }).catch(error => {
                    console.log(error)
                }) 
            })
        },
        //请求会话列表
        getExtraConverList(startTime){
            console.log('翻页时间戳'+startTime);
            RongIMLib.getConversationList({
                count: 40,
                startTime: startTime,
                order: 0
            }).then(res => {
                if (res.code === 0) {
                    console.log('翻页获取会话列表成功', res);
                    this.dealListCount(res.data);
                } else {
                    console.log(res.code, res.msg)
                }
            })
        },
        getTotalUnReadCount(){
            const conversationTypes = [RongIMLib.ConversationType.PRIVATE, RongIMLib.ConversationType.GROUP]
            RongIMLib.getTotalUnreadCount(true, conversationTypes).then(res => {
                if (res.code === 0) {
                    console.log(res.code, res.data)
                    this.$store.dispatch("currentUnReadCountAction", res.data);
                } else {
                    console.log(res.code, res.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        goToRong(){
            window.open(`${domain.account}/RongIM`)
        },
    }
}
</script>

<style scoped lang="scss">
.icon {
    cursor: pointer;
    &.iconMessage {
        position: relative;
        .icon-comment{
            font-size: 25px;
        }
        .tips {
            white-space: nowrap;
            font-size: 12px;
            border-radius: 2px;
            background-color: #1A1A1A;
            color: #fff;
            line-height: 1;
            padding: 3px 5px 5px;
            position: absolute;
            left: 50%;
            top: 26px;
            transform: translateX(-50%);
            display: none;
        }
        &:hover {
            .tips {
                display: block;
            }
        }
    }
}
</style>
