import { createStore } from 'vuex'

import user from './modules/user'
import message from './modules/message'
import im from './modules/im'
import common from './modules/common'

export default createStore({
  modules: {
    user,
    message,
    im,
    common
  }
})
