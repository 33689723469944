<template>
    <div class="fixed-box" :style="`height:${show ? '190px' : '140px'}`">
        <div class="fixed-item" @click="goQestion">
            <span class="icons icon-issue"></span>
            <div>常见问题</div>
        </div>
        <div class="fixed-item" @click="goReply">
            <span class="icons icon-feedback"></span>
            <div>问题反馈</div>
        </div>
        <div class="fixed-item" @click="goHelpCenter">
            <span class="icons icon-customer"></span>
            <div>在线客服</div>
        </div>
        <div
            :class="['fixed-item', {'fixed-item-hide': !show}]" 
            @click="scrollTop">
            <span class="icons icon-top"></span>
        </div>
    </div>
</template>

<script>
import MDRouter from "@/service/router"
export default {
    props: ["show"],
    methods: {
        goQestion() {
            window.open('https://zhongchou.modian.com/helpcenter/index')
        },
        goReply() {
            window.open('https://zhongchou.modian.com/helpcenter/reply?_ga=2.80450007.19177274.1666930957-1226901126.1666159598')
        },
        goHelpCenter() {
            MDRouter.goServeCenter()
        },
        scrollTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style lang="scss" scoped>
.fixed-box {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9999;
    height: 210px;
    transition: height 0.15s;
    .fixed-item {
        font-size: 20px;
        width: 40px;
        height: 40px;
        background-color: #F8F8F8;
        border-radius: 4px;
        border: 1px solid #E8E8E8;
        margin-bottom: 10px;
        color: #7F7F7F;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &-hide {
            visibility: hidden;
        }
        div {
            font-size: 12px;
            background-color: #1A1A1A;
            color: #fff;
            position: absolute;
            padding: 5px;
            line-height: 1;
            white-space: nowrap;
            border-radius: 2px;
            left: -100%;
            transform: translateX(-50%);
            display: none;
        }
        span {
            &.icon  {
                font-size: 20px;
            }
        }
        &:hover {
            color: #1A1A1A;
            border-color: #1A1A1A;
            position: relative;
            div{
                display: block;
            }
        }
    }
}
</style>