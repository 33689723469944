import Layout from '@/layout/default';
import LayoutNoMenu from '@/layout/defaultNoMenu';
import RouterView from '@@/compose/routerView.vue';

const userRouter = {
    path: '/user',
    name: 'user',
    component: RouterView,
    children: [{
        path: '',
        name: 'user-index',
        component: Layout,
        children: [
            {
                path: 'center', // 个人中心
                name: 'center',
                component: () => import('@/views/user/center.vue')
            },
            {
                path: 'follow', // 关注的人
                name: 'follow',
                component: () => import('@/views/user/follow.vue')
            },
            {
                path: 'like_project', // 看好的项目
                name: 'like_project',
                component: () => import('@/views/user/like_project.vue')
            },
            {
                path: 'creative_project', // 看好的创意
                name: 'creative_project',
                component: () => import('@/views/user/creative_project.vue')
            },
            {
                path: 'support_project', // 支持的项目
                name: 'support_project',
                component: () => import('@/views/user/support_project.vue')
            },
            {
              path: 'info', // 个人资料
              name: 'info',
              component: () => import('@/views/user/info.vue'),
            },
            {
                path: 'subscribe_project', // 参与的云养计划
                name: 'subscribe_project',
                component: () => import('@/views/user/subscribe_project.vue')
            }, {
              path: 'create_idea', // 发起的创意
              name: 'create-idea',
              component: RouterView,
              children: [{
                  path: '', // 个人空间
                  name: 'create_idea',
                  component: () => import('@/views/user/idea.vue')
              },{
                path: 'idea_history', // 历史更新
                name: 'idea_history',
                component: () => import('@/views/user/idea_update_history.vue')
              }]
            }
        ]
    }, {
      path: 'security', // 账户与安全
      name: 'security',
      component: Layout,
      children: [{
        path: '',
        name: 'security-index',
        component: () => import('@/views/user/security/security.vue'),
      },{
        path: 'setpassword', // 设置密码
        name: 'security-setpassword',
        component: () => import('@/views/user/security/setpassword.vue'),
      },{
        path: 'resetpassword', // 修改密码
        name: 'security-resetpassword',
        component: () => import('@/views/user/security/setpassword.vue'),
      },{
        path: 'updatemobile', // 更改手机号
        name: 'security-updatemobile',
        component: () => import('@/views/user/security/updatemobile.vue'),
      }]
    }, {
        path: '', // 个人空间
        name: 'user-noMenu',
        component: LayoutNoMenu,
        children: [{
            path: 'homepage/:id', // 个人空间
            name: 'user-homePage',
            component: () => import('@/views/user/home_page.vue')
        }]
    }]
}

export default userRouter;
