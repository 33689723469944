<template>
    <div class="pc-wrap">
        <CompHeader />
        <div class="main">
            <router-view />
        </div>
        <CompFooter ref="Footer"/>
        <CompFloating :show="show" :class="{'footer-box': footerStyle}"/>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CompHeader from './common/header'
import CompFooter from './common/footer'
import CompFloating from './common/floating'
export default {
    components: {
        CompHeader,
        CompFooter,
        CompFloating
    },
    computed: {
        ...mapState({
            userId: (state) => state.user.userId,
        }),
    },
    data() {
        return {
            show: false,
            footerStyle: false
        }
    },
    mounted() {
        document.addEventListener('scroll', this.styleFun)
        if (this.userId && this.userId !== this.sensors.store.getDistinctId()) {
            this.sensors.login(this.userId);
        }
    },
    methods: {
        styleFun(e) {
            let top = e.target.scrollingElement.scrollTop
            if (top > 200) {
                this.show = true
            } else {
                this.show = false
            }

            if (this.$refs.Footer) {
                let footerOffsetTop = this.$refs.Footer.$el.offsetTop
                let height = e.target.scrollingElement.clientHeight
                if (top + height > footerOffsetTop) {
                    this.footerStyle = true
                } else {
                    this.footerStyle = false
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pc-wrap {
    min-width: 1080px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    left: 0;
    top: 0;
    .main {
        flex: 1;
    }
    .footer-box {
        bottom: 216px;
        position: absolute;
    }
}
</style>