import { get, post } from '@/utils/apiBase.js';

// 获取pc站内信列表
const getCpMessageList = (option) => {
  return get('/apis/user_center/get_cp_message_list', option);
    // return get('https://mockapi.eolink.com/fWNEETAa7734cd4a3f09d2ff3aaf06f131e4c2f0f646607/apis/user_center/get_cp_message_list', option);
};
// 将站内信标记为已读
const readPcMessage = (option) => {
    return post('/apis/user_center/read_pc_message', option);
};
// 获取个人中心未读总数
const getMessageUnread = (option) => {
    return get('/apis/user_center/get_message_unread', option);
};
// 消除PC站内信未读消息
const cleanPcMessageCount = (option) => {
    return post('/apis/push/message/clean_pc_message_count', option);
};
// 私信未读数
const getUnreadCountApi = (option) => {
    return get('/apis/im/unread_count', option);
};
export default {
    getCpMessageList,
    readPcMessage,
    getMessageUnread,
    cleanPcMessageCount,
    getUnreadCountApi
};
