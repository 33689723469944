<template>
    <div class="header">
        <img class="logo" src="https://s.moimg.net/dist/img/modian-logo.png" @click="goIndex" />
        <template v-for="(item, index) in nav" :key="index">
            <div class="nav" @click="goLink(item.path)">{{item.name}}</div>
        </template>
        <!-- <el-button class="btn" type="primary" size="large" @click="goInitiate">{{hasPro ? '项目管理后台' : '发起众筹项目'}}</el-button> -->
        <el-button class="btn" type="primary" @click="goInitiate">发起众筹项目</el-button>
        <div v-if="showSearchIcon" class="icon icon-search marginL iconMessage" @click="showSearchIcon = false">
          <div class="tips">搜索</div>
        </div>
        <el-input
          v-else
          class="search-input marginL"
          v-model="searchValue"
          size="large"
          placeholder="请输入搜索内容"
          @keyup.enter="goSearch"
        >
          <template #prefix>
            <el-icon @click="goSearch" class="el-input__icon"><search /></el-icon>
          </template>
        </el-input>
        <template v-if="userDetail && cp_entrance">
          <div class="icon iconMessage marginL">
            <CompCount :is_cp="userDetail.is_cp"/>
          </div>
          <div class="icon iconMessage marginL" style="margin-top: -3px;">
            <el-badge :value="messageNum" :hidden="(messageNum < 1)">
              <div class="icon-information" @click="goMessage"></div>
            </el-badge>
            <div class="tips" v-if="messageNum > 0">{{messageNum}}条未读站内信</div>
            <div class="tips" v-else>站内信</div>
          </div>
        </template>
        <el-dropdown v-if="userId">
          <div class="user marginL" 
            :style="`background-image:url(${$imgUrlFilter(userIcon, 68)})`">
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="ifCenter">
                <div class="user-drop" @click="goUserInfo">
                  <span class="icon-my"></span>
                  <span class="name">个人中心</span>
                </div>
              </el-dropdown-item>
              <!-- 真CP和泛CP才可进入 -->
              <el-dropdown-item v-if="userCpTag.user_cp_crowd_tag_flag || userCpTag.common_user_cp_crowd_flag">
                <div class="user-drop" @click="goInitiate('cp')">
                  <span class="icon-other_business"></span>
                  <span class="name">项目管理后台</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="userCpTag.shop_user_cp_tag_flag">
                <div class="user-drop" @click="goPop">
                  <span class="icon-mall"></span>
                  <span class="name">我的店铺</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="userCpTag.shop_supplier_tag_flag">
                <div class="user-drop" @click="goSupplierCenter">
                  <span class="icon-storehouse"></span>
                  <span class="name">供货商后台</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="userCpTag.user_cp_ntf_tag_flag">
                <div class="user-drop" @click="goLockerr">
                  <span class="icon-contact"></span>
                  <span class="name">摩点宇宙后台</span>
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="user-drop" @click="goExit">
                  <span class="icon-exit"></span>
                  <span class="name">退出</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div v-else class="marginL login-btn" @click="goLogin">登录 / 注册</div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import domain from '@/service/domain'
import { mapState, mapActions } from 'vuex'
import CompCount from "@@/common/unreadCount.vue"
import { getLockerrCode } from "@/api/index"
export default {
  components: {
    CompCount,
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId,
      userIcon: state => state.user.userIcon,
      messageNum: state => state.message.messageNum,
      userDetail: state => state.user.userDetail,
      hasPro: state => state.user.hasPro,
      hasPop: state => state.user.hasPop,
      userCpTag: state => state.user.userCpTag
    }),
    ifCenter() {
      if (this.$route.fullPath == '/user/center') {
        return false
      } else {
        return true
      }
    },
    cp_entrance() { // 是否展示私信&站内信入口
      return(
        this.userCpTag.user_cp_tag_flag ||   // 是否是真cp
        this.userCpTag.common_user_cp_flag || // 是否是泛cp
        this.userCpTag.union_user_cp_flag ||  // 是否是联合发起人
        this.userCpTag.customer_user_cp_tag_flag // 是否是电商
      )
    }
  },
  data() {
    return {
      nav: [{
        name: '发现项目',
        path: `${domain.zhongchou}/?_mpos=h_nav_discover`
      }, {
        name: '创作者动态',
        path: `${domain.zhongchou}/dongtai/index?type=1&_mpos=h_nav_dongtai`
      }, {
        name: '话题',
        path: `${domain.zhongchou}/topic/index?type=1&_mpos=h_nav_topic`
      }, {
        name: '摩点App下载',
        path: `${domain.zhongchou}/extension/download?_mpos=h_nav_download_app`
      }, {
        name: '摩点商城',
        path: `${domain.wap}/product/`
      }],
      showSearchIcon: true,
      searchValue: ''
    }
  },
  async created() {
    if (this.userId) {
      this.getMessageUnread()
      this.getUserDetail()
      this.getCpTag()
    }
  },
  methods: {
    ...mapActions({
      getMessageUnread: "getMessageUnread",
      getUserDetail: "getUserDetail",
      getCpTag: "getCpTag"
    }),
    // 去首页
    goIndex() {
      location.href = domain.www
    },
    goLink(path) {
      if (path) {
        window.open(path)
      }
    },
    goInitiate(type) {
      if (type == 'cp') {
        window.open(`${domain.member}/project_manage/index`)
      } else {
        window.open(`${domain.account}/initiate`)
      }  
    },
    goPop() {
      window.open(`${domain.center}/product`)
    },
    goSearch() {
      location.href = `${domain.zhongchou}/searchout?key=${this.searchValue}`
    },
    goMessage() {
     this.$router.push('/message/list')
    },
    goUserInfo() {
      location.href = `${domain.www}/user/center`
    },
    goExit() {
      var hostname=window.location.hostname;
      var arr=hostname.split(".");
      var domain2= arr[arr.length-2]+"."+arr[arr.length-1];
      var aCookie = document.cookie.split(";");
      for (var i = 0; i < aCookie.length; i++) {
          var aCrumb = aCookie[i].split("=");
          var key = aCrumb[0].toString().trim();
          Cookies.remove(key, { path: '/', domain: domain2 })
          // document.cookie = key+'=null;expires='+new Date(0)+';max-age=0;domain='+domain2+';path=/;secure;';
      }
      window.location.reload()
    },
    goLogin() {
      location.href = domain.account
    },
    goSupplierCenter() {
      window.open(`${domain.member}/gonghuo/center`)
    },
    // 去数字藏品后台
    async goLockerr() {
        if (this.userId) {
            const { status, data } = await getLockerrCode()
            if (status == 0) {
                window.open(`https://shop-dc.lockerr.cn/#/login?from=modian&code=${data.code}`)
            } else {
                window.open(`https://shop-dc.lockerr.cn/#/login?from=modian`)
            }
        }
    },
  }
}
</script>

<style lang="scss">
.header {
  width: 100%;
  min-width: 1244px;
  padding: 0 40px;
  height: 70px;
  box-shadow: 0px 1px 0px 0px #F2F2F2;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 2001;
  .logo {
    width: 94px;
    margin-right: 40px;
    cursor: pointer;
  }
  .nav {
    font-size: 14px;
    margin-right: 30px;
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  .btn {
    margin-left: auto;
    cursor: pointer;
  }
  .search-input {
    width: 220px;
    .el-input__icon {
      font-size: 18px;
    }
  }
  .icon {
    font-size: 20px;
    cursor: pointer;
    &.iconMessage {
      position: relative;
      .tips {
        white-space: nowrap;
        font-size: 12px;
        border-radius: 2px;
        background-color: #1A1A1A;
        color: #fff;
        line-height: 1;
        padding: 3px 5px 5px;
        position: absolute;
        left: 50%;
        top: 26px;
        transform: translateX(-50%);
        display: none;
      }
      &:hover {
        .tips {
          display: block;
        }
      }
    }
  }
  .user {
    width: 34px;
    height: 34px;
    border: 2px solid #DEE0E1;
    border-radius: 50%;
    background: no-repeat center;
    background-size: 100%;
    cursor: pointer;
  }
  .login-btn {
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
  }
  .marginL {
    margin-left: 40px;
  }
}
.user-drop {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 16px;
  padding: 5px 4px;
  .name {
    margin-left: 5px;
  }
}
</style>
