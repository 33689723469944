import Layout from '@/layout/default'
const userRouter = {
    path: '/order',
    name: 'order',
    component: Layout,
    children: [
        {
            path: 'order_list', // 订单列表
            name: 'order_list',
            component: () => import('@/views/order/list.vue')
        },
        {
            path: 'project_order_detail', // 众筹订单
            name: 'details',
            component: () => import('@/views/order/details.vue')
        },
        {
            path: 'product_order_detail', // 商品订单
            name: 'detailsShop',
            component: () => import('@/views/order/detailsShop.vue')
        }
    ]
}

export default userRouter
