import { get, post } from '@/utils/apiBase.js'
const projectVote = option => {
    return post('/apis/project/vote', option)
}
const getProjectUpdateDetail = option => {
    return post('/apis/project_zc/update_detail', option)
    // return post('/honor/product/update_detail', option)
}
// 获取更新详情
const getUpdateDetail = option => {
    return post('/honor/product/update_detail', option)
}
// 点赞
const postFavorUpdate = option => {
    return post('/v45/main/favor_update', option)
}
// 获取新的抽奖详情
const getNewDrawInfo = option => {
    return get('/apis/project/cp_admin/get_activity_card_info', option)
}
// 获取小程序码
const getMiniAppQrcode = (option) => {
    return post('/apis/project/get_mini_app_qrcode', option)
}
export default {
    getUpdateDetail,
    getProjectUpdateDetail,
    projectVote,
    postFavorUpdate,
    getNewDrawInfo,
    getMiniAppQrcode
}