import store from '../store'
const MDRouter = {
    // 在线客服
    goServeCenter: function() {
        if (store.state.common.sobotUrl || store.state.common.sobotUrl_nologin) {
            window.open(store.state.common.sobotUrl || store.state.common.sobotUrl_nologin)
        } else {
            store.dispatch('getSoboUrl')
        }
    }
}
export default MDRouter