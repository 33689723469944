import LayoutNoMenu from '@/layout/defaultNoMenu';
const identityRouter = {
  path: '/identity',
  name: 'identity',
  component: LayoutNoMenu,
  children: [
      {
          path: 'type', 
          name: 'identity-index',
          component: () => import('@/views/identity/index.vue')
      },
  ]
}
export default identityRouter;
