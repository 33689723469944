<template>
  <div class="footer">
    <div class="copy">
      <img src="https://s.moimg.net/dist/img/logo-grey.png" />
      <div class="intro">Copyright © 2014-{{ current_year }} MoDian All Rights Reserved 北京摩点会想科技有限公司</div>
      <!-- <div class="icon icon-footer_qq"></div>-->
      <div v-for="item in iconArr" :key="item" :class="['icon', `icon-${item.name}`]" @click="goLink(item.link)">
      </div>
    </div>
    <div class="icp">
      <div class="icp-top flex">
        <a class="modian_ghs" href="https://zhongchou.modian.com/about/index/1/15.html"
          target="_blank">京公网安备11010502031287号</a>
        <a href="https://zhongchou.modian.com/about/index/1/9.html" target="_blank">增值电信业务经营许可证：京B2-20192895</a>
        <a href="https://beian.miit.gov.cn" target="_blank">京ICP备14022922号-1</a>
        <a href="https://zhongchou.modian.com/about/index/1/10.html" target="_blank">京网文[2017]0764-164号</a>
        <a href="https://zhongchou.modian.com/about/index/1/11.html" target="_blank">出版物经营许可证</a>
        <a href="https://zhongchou.modian.com/about/index/1/12.html" target="_blank">京食药网食备201910011</a>
        <a href="https://zhongchou.modian.com/about/index/1/14.html" target="_blank">营业执照</a>
      </div>
      <div class="icp-top flex">
        <a href="https://www.12377.cn/" target="_blank">网上有害信息举报</a>
        <a href="https://zhongchou.modian.com/about/index/1/16.html" target="_blank">广播电视节目制作经营许可证：(京)字第14988号</a>
        <a target="_blank">2014年雏鹰计划专项资金扶持企业</a>
        <a href="https://zhongchou.modian.com/about/index/1/13.html" target="_blank">京演（机构）[2019] 4218号</a>
        <a href="tel:4009004587-6" target="_blank">违法和不良信息举报电话：4009004587-6</a>
      </div>
      <div class="icp-bottom flex">
        <a href="mailto:jubao@modian.com" target="_blank">举报邮箱：jubao@modian.com</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconArr: [{
        name: 'footer_weibo',
        link: 'https://weibo.com/p/1006063264015433'
      }, {
        name: 'footer_wechat'
      }, {
        name: 'footer_zhihu',
        link: 'https://www.zhihu.com/org/mo-dian-44-58'
      }, {
        name: 'footer_douyin',
        link: 'https://www.douyin.com/user/MS4wLjABAAAA_4Poc031XX5Tes_jGN6N4rGkod-KnUr3kKwMvnt13QG7mWwiEo2iYR8wT-GWjQLS'
      }, {
        name: 'footer_red',
        link: 'https://www.xiaohongshu.com/user/profile/5c64ebb10000000011037d24'
      }],
      current_year: new Date().getFullYear()
    }
  },
  methods: {
    goLink(link) {
      if (link) {
        location.href = link
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #F8F8F8;

  .copy {
    width: 1080px;
    height: 90px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;

    img {
      width: 94px;
      margin-right: 30px;
    }

    .intro {
      flex: 1;
    }

    .icon {
      margin-left: 22px;
      color: #B1B1B1;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: #1A1A1A;
      }

      &-footer_wechat {
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 100px;
          height: 100px;
          padding: 10px;
          box-shadow: 0 6px 30px 0 rgb(0 0 0 / 10%);
          background: #fff url('https://s.moimg.net/dist/img/wxcode.png') center no-repeat;
          background-size: 100px 100px;
          position: absolute;
          left: 50%;
          top: -130px;
          transform: translateX(-50%);
          display: none;
        }

        &:hover {
          &::after {
            display: block;
          }
        }
      }
    }
  }

  .icp {
    width: 1080px;
    height: 93px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #7F7F7F;
    font-size: 12px;
    line-height: 1;

    a {
      color: #7F7F7F;

      &:hover {
        color: #1A1A1A;
      }
    }

    &-top {
      padding: 0 42px 10px 0;
    }

    &-bottom {
      padding-right: 10px;

    }

    .modian_ghs {
      padding-left: 15px;
      background: url('https://s.moimg.net/dist/img/modian_ghs1.png') no-repeat left center;
      background-size: 10px 12px;

      &:hover {
        background-image: url('https://s.moimg.net/dist/img/modian_ghs2.png');
      }
    }

    .flex {
      display: flex;
      justify-content: space-between;
    }
  }
}</style>