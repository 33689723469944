<template>
    <div class="menu-box">
        <div class="menu-item" v-for="(item, index) in menu_list" :key="index">
            <div :class="[
                'tit',
                { 'hover': $route.path == item.path },
                { haslink: !(item.children && item.children.length) }
            ]" @click="pushRouter(item)">
                <span v-if="item.icon" :class="['item-icon', item.icon]"></span>
                <span>{{ item.name }}</span>
            </div>
            <template v-if="item.children">
                <div v-for="(itemst, indexst) in item.children" :key="indexst" :class="[
                    'item',
                    { 'hover': $route.path == itemst.path },
                    { 'first': indexst == 0 }
                ]" @click="itemst.name == '私信' ? goToRong() : pushRouter(itemst)">
                    <span v-if="itemst.isShow">
                        {{ itemst.name }}
                    </span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import MDRouter from "@/service/router"
import domain from '@/service/domain'
import { mapState } from "vuex"
import { getLockerrCode, getAuthInfo } from "@api/index"
export default {
    computed: {
        ...mapState({
            userId: state => state.user.userId,
            userDetail: state => state.user.userDetail
        }),
        menu_list() {
            const list = [{
                name: '我的主页',
                icon: 'icon-my',
                path: '/user/center'
            }, {
                name: '我的动态',
                icon: 'icon-trends',
                methods: 'goDynamic'
            }, {
                name: '我的创意',
                icon: 'icon-idea',
                path: '/user/create_idea'
            }, {
                name: '我的订单',
                icon: 'icon-orders',
                path: '/order/order_list'
            }, {
                name: '我的关注',
                icon: 'icon-collect',
                children: [{
                    name: '我的藏品',
                    methods: 'goDigital',
                    isShow: true
                }, {
                    name: '关注的人',
                    path: '/user/follow',
                    isShow: true
                }, {
                    name: '支持的项目',
                    path: '/user/support_project',
                    isShow: true
                }, {
                    name: '看好的项目',
                    path: '/user/like_project',
                    isShow: true
                }, {
                    name: '看好的创意',
                    path: '/user/creative_project',
                    isShow: false
                }, {
                    name: '参与的云养计划',
                    path: '/user/subscribe_project',
                    isShow: true
                }]
            }, {
                name: '我的设置',
                icon: 'icon-set',
                children: [{
                    name: '个人资料',
                    path: '/user/info',
                    isShow: true
                }, {
                    name: '账号认证',
                    methods: 'goAuth',
                    isShow: true
                }, {
                    name: '实名认证',
                    path: '/identity/verify',
                    isShow: false
                }, {
                    name: '管理地址簿',
                    path: '/address/address_list',
                    isShow: true
                }, {
                    name: '账户与安全',
                    path: '/user/security',
                    isShow: true
                }]
            }, {
                name: '联系摩点',
                icon: 'icon-contact',
                children: [{
                    name: '常见问题',
                    path: 'https://zhongchou.modian.com/helpcenter/index',
                    isShow: true
                }, {
                    name: '建议与反馈',
                    path: 'https://zhongchou.modian.com/helpcenter/reply?_ga=2.80450007.19177274.1666930957-1226901126.1666159598',
                    isShow: true
                }, {
                    name: '人工客服',
                    methods: 'goHelpCenter',
                    isShow: true
                }]
            }]
            return list
        }
    },
    methods: {
        pushRouter(item) {
            if (item.methods) return this[item.methods]()
            if (item.path) {
                if (item.path.indexOf('https://') > -1) {
                    window.open(item.path)
                } else {
                    this.$router.push(item.path)
                }
            }
        },
        // 去lockerr
        async goDigital() {
            if (this.userId) {
                const { status, data } = await getLockerrCode()
                if (status == 0) {
                    window.open(`https://${process.env.VUE_APP_ENV == 'test' ? 'test.' : ''}modianverse.com/#/feature/center?code=${data.code}`)
                } else {
                    window.open(`https://${process.env.VUE_APP_ENV == 'test' ? 'test.' : ''}modianverse.com/#/feature/center`)
                }
            }
        },
        // 发布动态
        goDynamic() {
            window.open(domain.zhongchou + '/dongtai/index?tab=1')
        },
        // 人工客服
        goHelpCenter() {
            MDRouter.goServeCenter()
        },
        // 账号认证
        async goAuth() {
            if (this.userId) {
                const { status, data } = await getAuthInfo()
                if (status == 0) {
                    let auth_status = data.auth_status;
                    let auth_cate = data.auth_cate;
                    let history_pass = data.history_pass;
                    let AuthUrl = domain.www + '/identity/type';
                    let newTab = window.open('about:blank');
                    if (Number(history_pass) == 1) {
                        if (auth_cate == 11) {
                            AuthUrl = domain.me + '/u/user_self_edit?uid=' + this.userId + '&from=cp'
                        } else {
                            AuthUrl = domain.me + '/u/user_auth_view?uid=' + this.userId + '&from=cp'
                        }
                    } else {
                        if (auth_status == 0) {
                            AuthUrl = domain.www + '/identity/type'
                        } else if (auth_status == 100) {
                            if (history_pass == 1) {
                                AuthUrl = domain.zhongchou + '/auth/auth_funds_success?type=me'
                            } else {
                                AuthUrl = domain.zhongchou + '/auth/auth_funds_success'
                            }
                        } else if (auth_status == 201) {
                            AuthUrl = domain.zhongchou + '/auth/auth_refuse_funds'
                        } else {
                            if (auth_cate == 11) {
                                AuthUrl = domain.me + '/u/user_self_edit?uid=' + this.userId
                            } else {
                                AuthUrl = domain.me + '/u/user_auth_view?uid=' + this.userId
                            }
                        }
                    }
                    newTab.location.href = AuthUrl;
                }
            }
        }
        // 去私信
        // goToRong(){
        //     let newTab = window.open('about:blank');
        //     newTab.location.href = `${domain.account}/RongIM`
        // },
        // 动态管理
        // goDynamicM() {
        //     location.href = domain.me + '/dynamic/manager'
        // }
    }
}
</script>

<style lang="scss" scoped>
.menu-box {
    width: 240px;
    padding: 27px 10px 10px;
    background-color: #F8F8F8;

    .menu-item {
        margin-bottom: 20px;

        .tit {
            padding-left: 47px;
            font-weight: bold;
            font-size: 18px;
            line-height: 40px;
            position: relative;
            border-radius: 4px;

            &.haslink {
                &:hover {
                    cursor: pointer;
                    background-color: #E8E8E8;
                }
            }

            &.hover {
                cursor: pointer;
                background-color: #E8E8E8;
            }

            .item-icon {
                position: absolute;
                font-size: 20px;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .item {
            font-size: 16px;
            line-height: 40px;
            cursor: pointer;
            padding-left: 47px;

            &.first {
                margin-top: 2px;
            }

            &:hover,
            &.hover {
                border-radius: 4px;
                background-color: #E8E8E8;
            }

            &.hover {
                font-weight: bold;
            }
        }
    }
}
</style>
