import { get, post } from '@/utils/apiBase.js'
// 订单title
const getOrderTitleList = option => {
  return get('/apis/mdorder/order_title_list', option)
}
const getOrderList = option => {
  return get('/apis/mdorder/order_list', option)
}
const getOrderDetail = option => {
  return get('/apis/mdorder/order_detail', option)
}
const getMallOrderDetail = option => {
  return get('/mall/order/detail', option)
}
const getMallOrderDetailV2 = option => {
  return get('/apis/mall/order/detail_v2', option)
}
const getOrderDetailV2 = option => {
  return get('/apis/mdorder/order_detail_v2', option)
}
const getScheme = option => {
  return get('/apis/weixin_api/get_scheme', option)
}
// 获取抽卡
const orderCards = option => {
  return get('/apis/mdorder/cards', option)
}

// 获取拆盒
const orderPacks = option => {
  return get('/apis/mdorder/order_packs', option)
}
const MallPreviewOrder = option => {
  return post('/mall/preview/order', option)
}

// 创建订单，获取pay_id --电商
const CreateOrder = option => {
  return post('/mall/order', option)
}

// 创建订单，获取pay_id --众筹
const CreateOrderPro = (option) => {
  return post('/main/add_back', option, {
    apiShow: false
  })
}

const OrderSendPay = option => {
  return post('/mall/order/sendpay', option)
}
// 获取payId
const OrderResetPay = option => {
  return post('/mall/order/resetpay', option)
}
// 获取title（新）
const getNewOrderTitleList = option => {
  return get('/apis/mdorder/user_person_title_list', option)
}
// 订单列表V2版本
const getOrderListV2 = option => {
  return get('/apis/mdorder/order_list_v2', option)
}
export default {
  getOrderTitleList,
  orderPacks,
  orderCards,
  getOrderList,
  getOrderDetail,
  getScheme,
  getMallOrderDetail,
  getMallOrderDetailV2,
  getOrderDetailV2,
  MallPreviewOrder,
  CreateOrder,
  CreateOrderPro,
  OrderSendPay,
  OrderResetPay,
  getNewOrderTitleList,
  getOrderListV2
}
