import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-cropper/dist/index.css'
import '@/assets/styles/main.scss'
import '@/assets/styles/render.scss'

import VueCropper from 'vue-cropper';
import draggable from 'vuedraggable'
import ElementPlus from 'element-plus'
// import 'md-base-tools/editor/index.css'
import '@/assets/styles/editor.scss'
import 'element-plus/dist/index.css'
import '$md-base-ui/src/assets/styles/element-variarbles.scss'
import '@/assets/styles/message_box.scss'
import locale from "element-plus/lib/locale/lang/zh-cn";
import * as ElIcons from '@element-plus/icons-vue'

import imgUrlFilter from '$md-base-tools/imgUrlFilter'

import "$md-base-ui/md-ui-v3/style.css"
import MDUIV3 from "$md-base-ui/md-ui-v3/md-ui-v3"

import dayjs from 'dayjs'
dayjs().format()
import tools from '@/utils/tools.js'
const app = createApp(App)

// 神策
import '@/service/sensors'
var sensors = require('sa-sdk-javascript')
app.config.globalProperties.sensors = sensors

for (const name in ElIcons){
	app.component(name, ElIcons[name])
}

console.log('ElementPlus', ElementPlus)
app.component('draggable', draggable)
app.use(store).use(router).use(VueCropper).use(ElementPlus, {locale}).use(MDUIV3)
app.config.globalProperties.$imgUrlFilter = imgUrlFilter
app.config.globalProperties.$dayjs = dayjs

router.beforeEach(async () => {
	if (!store.state.user.userId) {
        await store.dispatch('getUserInfo')
    }
})
window.alert = function(message) {
    tools.confirm(message);
}
app.mount('#app')
