import { getIMToken } from "@/api/index"

const state = () => ({
    appkeyTest: '3argexb63sdbe',//测试服
    appkey:'ik1qhw09iyo0p',//生产环境
    rongYunToken: '', // 融云token
    unReadCount: 0, //  未读数
    connected: false //链接状态
})

const mutations = {
  currentToken(state, update) {
    state.rongYunToken = update
  },
  currentConnected(state, update) {
    state.connected = update
  },
  currentUnReadCount(state, update) {
    state.unReadCount = update
  }
}

const actions = {
    currentConnectedAction({commit}, connected) {
        commit('currentConnected',connected)
    },
    currentUnReadCountAction({commit}, unReadCount) {
        commit('currentUnReadCount',unReadCount)
    },
    // 获取融云token
    async getImToken({commit}, uid) {
        const { status, data } = await getIMToken({
            uid: uid
        })
        if (status == 0) {
            commit('currentToken', data.token)
        }
    },
}

export default {
  state,
  mutations,
  actions
}
