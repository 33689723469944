import LayoutNoMenu from '@/layout/defaultNoMenu';
const projectRouter = {
  path: '/project',
  name: 'project',
  component: LayoutNoMenu,
  children: [
      {
          path: 'update/detail/:id', 
          name: 'update_detail',
          component: () => import('@/views/project/update/_id.vue')
      },
  ]
}
export default projectRouter;
