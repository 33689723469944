import { get } from '@/utils/apiBase.js';

// 协议-获取协议内容
const getprotocalList = (option) => {
    return get('/apis/admin/bottom/list', option);
  };

// 协议-获取协议内容-通过father_id和son_id
const getprotocalFaterSonDetail = (option) => {
  return get('/apis/admin/bottom/fater_son/detail', option);
};

const getprotocalDetail = (option) => {
    return get('/apis/admin/bottom/detail', option);
  };

export default {
    getprotocalList,
    getprotocalFaterSonDetail,
    getprotocalDetail
};
