import Layout from '@/layout/default'
const digitalRouter = {
    path: '/address',
    name: 'address',
    component: Layout,
    children: [
        {
            path: 'address_list', // 管理地址簿
            name: 'address-list',
            component: () => import('@/views/address/list.vue')
        },
        {
            path: 'add_address',
            name: '地址详情',
            meta: {
              title: '地址详情'
            },
            component: () => import('@/views/address/address.vue')
          },
          {
            path: 'add_address/:id',
            name: '编辑地址',
            meta: {
              title: '编辑地址'
            },
            component: () => import('@/views/address/address.vue')
          }
    ]
}

export default digitalRouter
