import LayoutNoMenu from '@/layout/defaultNoMenu'
const HomeRouter = {
    path: '/message',
    name: 'message',
    component: LayoutNoMenu,
    children: [
        {
            path: 'list',
            name: 'message-list',
            component: () => import('@/views/message/index.vue')
        }
    ]
}

export default HomeRouter
