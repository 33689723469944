import { get, post } from '@/utils/apiBase.js';

// 个人中心详情
const userDetail = (option) => {
  return get('/v45/user/detail', option);
};

// 关注的人
const followList = (option) => {
  return get('/apis/comm/user/follow_list', option);
};
// 粉丝
const fanList = (option) => {
  return get('/apis/comm/user/fan_list', option);
};
// 关注
const follow = (option) => {
  return post('/apis/comm/user/follow', option);
};

const homeRecommend = (option) => {
  return get('/hotspot/new_home_recommend', option);
};
// 个人信息
const GetUserInfo = (option) => {
  return get('/apis/comm/user/user_info', option);
};
// 发起的众筹
const GetBuildProductList = (option) => {
  return get('/apis/user_center/build_product_list', option);
};
// 参与的云养计划列表
const GetSubscribePro = (option) => {
  return post('/apis/user_order/subscribe_pro', option);
};
// 支持的项目
const backProductList = (option) => {
  return post('/apis/user_center/back_product_list', option);
};
// 看好的项目
const myFavorsProductList = (option) => {
  return post('/apis/user_center/my_favors_product_list', option);
};
// 看好创意
const bullProductList = (option) => {
  return get('/apis/user_center/bull_product_list', option);
};
// 动态列表
const getCommFeedU = (option) => {
  return get('/apis/comm/feed/u', option);
};
// 获取用户融云token
const getIMToken = (option) => {
  return get('/im/utoken', option);
};
// 解绑微信、微博 platform: weixin/weibo
const unbinding = (option) => {
  return post('/account/api_unbinding', option);
};
// 设置、修改密码
const setUserPassword = (option) => {
  return post('/account/set_user_password', option);
};
// 更改手机号
const updateMobile = (option) => {
  return post('/account/update_mobile', option);
};
// 获取验证码
const sendVcode = (option) => {
  return post('/sms/send_vcode', option);
};
const userInfo = (option) => {
  return get('/apis/comm/user/user_info', option);
};
const getAuthStateInfo = (option) => {
  return post('/apis/auth_account/get_auth_state_info', option);
};
// cp获取创意详细信息
const getIdeaList = (option) => {
  return get('/apis/project/cp/idea_list', option);
};
// 获取创意类别列表
const getIdeaCategoryList = (option) => {
  return get('/apis/project/cp/get_idea_category_list', option);
};
// 删除创意
const deleteIdea = (option) => {
  return post('/apis/project/cp/delete_idea', option);
};
// 申请人工审核
const ideaApplyReview = (option) => {
  return post('/apis/project/cp/idea_apply_review', option);
};
// 众筹决定
const ideaToProjectDecision = (option) => {
  return post('/apis/project/cp/project_decision', option);
};
// 创意转众筹
const ideaToProject = (option) => {
  return post('/apis/product/idea_to_project', option);
};
// 设置创意发布时间
const setIdeaReleaseTime = (option) => {
  return post('/apis/project/cp/set_idea_release_time', option);
};
// cp获取创意更新列表
const ideaUpdateList = (option) => {
  return get('/apis/project/cp/idea_update_list', option);
};
// 创意更新置顶操作
const topIdeaUpdate = (option) => {
  return post('/apis/project/cp/top_idea_update', option);
};
// 删除创意更新
const delIdeaUpdate = (option) => {
  return post('/apis/project/cp/delete_idea_update', option);
};
// 创意更新申请人工复审
const ideaUpdateManualAudit = (option) => {
  return post('/apis/project/cp/idea_update_manual_audit', option);
};
// 获取用户cp标签
const GetUserCpTag = (option) => {
  return get('/apis/user/user_cp_tag', option);
}
// 查询自己的认证信息
const getAuthInfo = (option) => {
  return get('/apis/userauth/query/info', option);
};
// 查询用户是否是供货商
const GetIsSupplier = (option) => {
  return get('/apis/user/is_supplier', option);
};
export default {
  userInfo,
  getAuthStateInfo,
  userDetail,
  followList,
  fanList,
  follow,
  homeRecommend,
  GetUserInfo,
  GetBuildProductList,
  GetSubscribePro,
  backProductList,
  myFavorsProductList,
  bullProductList,
  getCommFeedU,
  getIMToken,
  unbinding,
  setUserPassword,
  updateMobile,
  sendVcode,
  getIdeaList,
  getIdeaCategoryList,
  deleteIdea,
  ideaApplyReview,
  ideaToProjectDecision,
  ideaToProject,
  setIdeaReleaseTime,
  ideaUpdateList,
  topIdeaUpdate,
  delIdeaUpdate,
  ideaUpdateManualAudit,
  GetUserCpTag,
  getAuthInfo,
  GetIsSupplier
};
