import { getSobotUrl } from "@/api/index"

const state = () => ({
    sobotUrl_nologin: '', // 在线客服地址-未登录状态
    sobotUrl: '', // 在线客服地址-登录状态
})

const mutations = {
    currentSoboUrlNoLogin (state, update) {
        state.sobotUrl_nologin = update
    },
    currentSoboUrl (state, update) {
        state.sobotUrl = update
    }
}

const actions = {
    async getSoboUrl({ rootState, commit }) {
        const { status, data, message } = await getSobotUrl()
        if (status == 0) {
            if (rootState.user.userId) {
                commit('currentSoboUrl', data.sobot_url)
            } else {
                commit('currentSoboUrlNoLogin', data.sobot_url)
            }
            window.open(data.sobot_url)
        } else {
            alert(message || '请求失败')
        }
    }
}

export default {
    state,
    mutations,
    actions
}
