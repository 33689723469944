import LayoutNoMenu from '@/layout/defaultNoMenu';

const protocalRouter = {
    path: '/protocal',
    name: 'protocal',
    component: LayoutNoMenu,
    children: [
        {
            path: ':fater_id/:son_id', 
            name: 'protocal',
            component: () => import('@/views/protocal/_id.vue')
        },
    ]
}

export default protocalRouter
