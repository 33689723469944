import { ElMessageBox } from 'element-plus'
/* eslint-disable */
const tools = {
    confirm(args, callBack, cancelBack) {
        let msg = "",
            success = "确定",
            cancel = "取消",
            title = "提示",
            align = "",
            hasCancel = true,
            distinguishCancelAndClose = true,
            dangerouslyUseHTMLString = false
        if (typeof args === "object") {
            if (args.title) title = args.title
            if (args.msg) msg = args.msg
            if (args.success) success = args.success
            if (args.cancel) cancel = args.cancel
            if (args.align) align = args.align
            if (args.hasCancel) hasCancel = args.hasCancel
            if (args.dangerouslyUseHTMLString) dangerouslyUseHTMLString = args.dangerouslyUseHTMLString
            if (args.distinguishCancelAndClose) distinguishCancelAndClose = args.distinguishCancelAndClose
        } else {
            if (args) msg = args
        }
        if (args.cancel){
            ElMessageBox.confirm(msg, title, {
                autofocus: false,
                distinguishCancelAndClose:distinguishCancelAndClose,
                cancelButtonText: cancel,
                confirmButtonText: success,
                dangerouslyUseHTMLString:dangerouslyUseHTMLString
              }).then(() => {
                if(typeof callBack === "function"){
                    callBack()
                }
              }).catch((action) => {
                if(action === 'cancel' && typeof cancelBack === "function"){
                    cancelBack()
                }
              })
        }else{
            ElMessageBox.alert(msg, title, {
                autofocus: false,
                confirmButtonText: success,
                dangerouslyUseHTMLString:dangerouslyUseHTMLString,
                callback: () => {
                    if(typeof callBack === "function"){
                        callBack()
                    }
                },
            })
        }
    }
}

tools.includeLinkStyle = url => {
    let link = document.createElement("link")
    link.rel = "stylesheet"
    link.type = "text/css"
    link.href = url
    document.getElementsByTagName("head")[0].appendChild(link)
}

tools.dynamicScript = (url, callBack) => {
    let script = document.createElement('script')
    let head = document.getElementsByTagName('head')[0]
    script.type = "text/javascript"
    script.defer = true
    script.async = true
    script.src = url
    head.appendChild(script)
    if (typeof callBack === "function") {
        if (script.readyState) { //IE　　
            script.onreadystatechange = () => {
                if (/complete|loaded/.test(script.readyState)) {　　
                    script.onreadystatechange = null
                    callBack()
                }
            }
        } else {
            script.onload = () => {
                callBack()
            }
        }
    }
}
tools.dateFormat = (dateStr) => {
    if (typeof dateStr === 'string') {
        dataStr = dateStr.replace(/-/ig, '/');
    }
    var date = dateStr ? new Date(dateStr) : new Date();
    var year, month, day, result = [];
    year = date.getFullYear();
    month = date.getMonth() + 1 > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1);
    day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    // hour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    // minute = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
    // seconds = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
    result.push(year, month, day);
    return result;
}
// 数据格式化-两位小数 
tools.fmoney = function (s, n) {
    let m = ''
    if (String(s).indexOf('-') !== -1) {
      m = '-'
      s = -Number(s)
    }
    n = String(s).indexOf('.') !== -1 ? 2 : (n || 0)
    s = String(parseFloat(String(s).replace(/[^\d\\.-]/g, '')).toFixed(n))
    var l = s.split('.')[0].split('').reverse()
    var r = s.indexOf('.') !== -1 ? '.' + s.split('.')[1] : ''
    var t = ''
    for (let i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '')
    }
    return (m + t.split('').reverse().join('') + r)
}
export default tools
export const debounce = (func, immediate, wait = 1000) => {
    let timer

    return function () {
        const context = this,
            args = arguments
        if (timer) clearTimeout(timer)
        if (immediate) {
            timer = setTimeout(() => {
                func.apply(context, args)
            }, wait)
        } else {
            const status = !timer
            timer = setTimeout(() => {
                timer = null
            }, wait)
            if (status) func.apply(context, args)
        }
    }
}

export const decodeEntities = (encodedString) => {
    var divElement = document.createElement("div");
    divElement.innerHTML = encodedString;
    return divElement.innerText;
}
export const fmTime = function (time) {
    const cdTime = time
    const day = Math.floor(cdTime / (24 * 60 * 60))
    let hour = Math.floor((cdTime % (24 * 60 * 60)) / (60 * 60))
    let minute = Math.floor(((cdTime % (24 * 60 * 60)) % (60 * 60)) / 60)
    let sec = Math.floor(((cdTime % (24 * 60 * 60)) % (60 * 60)) % 60)
    hour = hour > 9 ? hour : '0' + hour
    minute = minute > 9 ? minute : '0' + minute
    sec = sec > 9 ? sec : '0' + sec
    if (time > 24 * 60 * 60) {
      return [sec, minute, hour, day]
    } else if (time > 60 * 60) {
      return [sec, minute, hour]
    } else if (time > 60) {
      return [sec, minute]
    } else {
      return [sec]
    }
  }
