import Layout from '@/layout/defaultNoMenu'
const elementuiRouter = {
    path: '/elementui',
    name: 'elementui',
    component: Layout,
    children: [
        {
            path: '', 
            name: '',
            component: () => import('@/views/elementui/index.vue')
        },
    ]
}

export default elementuiRouter
