import { getMessageUnread } from "@/api/index"

const state = () => ({
    messageNum: 0, // 站内信未读数
})

const mutations = {
  currentMessage(state, update) {
    state.messageNum = update
  }
}

const actions = {
  // 获取站内信未读数
  async getMessageUnread({ commit }) {
    const { status, data } = await getMessageUnread()
    if (status == 0) {
        commit('currentMessage', data.unread_msg_total)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
